<!--
 * @Author: jiang
 * @Date: 2021-07-12 09:34:19
 * @Description: 流程、要点、制度; 部门管理员有侧边栏，其他用户查询自己部门下的
-->

<template>
  <el-container
    class="g-page-list"
    style="height: 100%; padding: 0 15px 15px; overflow: hidden;"
  >
    <el-aside
      class="g-bg g-mr-15"
      v-if="userSuperId === userId || userId==183|| userId==188"
    >
      <el-scrollbar
        y
        view-style="padding: 10px;"
      >
        <dept-tree @change="onDeptChange"></dept-tree>
      </el-scrollbar>
    </el-aside>
    <el-main class="g-bg">
      <el-scrollbar
        y
        view-style="padding: 15px;"
      >
        <el-form
          ref="form"
          inline
          :model="searchData"
          label-position="top"
        >
          <el-form-item
            label="标题"
            prop="title"
          >
            <el-input
              v-model="searchData.title"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            label="我创建的"
            prop="user_id"
          >
            <el-select v-model="searchData.user_id">
              <el-option
                label="是"
                :value="userId"
              ></el-option>
              <el-option
                label="否"
                value=""
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="vertical-align: bottom;">
            <c-btn-search
              @search="onRefresh"
              @reset="onReset"
              @create="onCreate"
            ></c-btn-search>
          </el-form-item>
        </el-form>

        <el-table :data="tableData">
          <el-table-column
            label="ID"
            prop="id"
            width="100px"
          />
          <el-table-column
            label="标题"
            prop="title"
            show-overflow-tooltip
          />
          <el-table-column
            label="时间"
            prop="created_at"
            width="160px"
          />
          <el-table-column
            label="操作"
            align="center"
            width="160px"
          >
            <template slot-scope="{ row }">
              <c-btn-table
                :show="['info', row.user_id === userId ? 'delete' : '']"
                @info="onInfo(row)"
                @delete="onDelete(row)"
              ></c-btn-table>
            </template>
          </el-table-column>
        </el-table>

        <b-pagination
          :page="page"
          @change="fetchList"
        ></b-pagination>
      </el-scrollbar>

      <b-dialog
        ref="detailDialog"
        width="800px"
        title="新建"
      >
        <detail-view
          :data="formData"
          :type="currentType"
          @success="onDetailSuccess"
        ></detail-view>
      </b-dialog>

      <b-dialog
        ref="infoDialog"
        width="800px"
        title="详情"
      >
        <info-view :id="currentRow.id"></info-view>
      </b-dialog>
    </el-main>
  </el-container>
</template>

<script>
import DeptTree from '@/components/tree/dept.vue'
import DetailView from './list-detail.vue'
import InfoView from './list-info.vue'
import {
  // createRecods,
  
  getRecodsPage,
  deleteRecods,
} from '@/api/manager'

export default {
  components: {
    DeptTree,
    DetailView,
    InfoView,
  },
  data() {
    return {
      userSuperId: +process.env.VUE_APP_SUPER_USER_ID,
      searchData: {
        title: '',
        user_id: '',
      },
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      tableData: [],
      formData: {},
      currentRow: {},
      currentType: '',
      currentDept: '',
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
    userDepartmentId() {
      return this.$store.state.user.userInfo.department.id
    },
  },
  watch: {
    '$route.path': {
      handler(path) {
        console.log(path)
        const typeMap = {
          '/manager/attention': '要点',
          '/manager/flow': '流程',
          '/manager/system': '制度',
        }
        this.currentType = typeMap[path]
        this.fetchList()
      },
      immediate: true,
    },
  },
  methods: {
    fetchList() {
      const params = {
        title: this.searchData.title,
        type: this.currentType,
        user_id: this.searchData.user_id,
        department_id: this.currentDept,
        current_page: this.page.current,
        current_count: this.page.size,
      }
      if (this.userSuperId !== this.userId) {
        params.department_id = this.$store.state.user.userInfo.department.id
      }
      getRecodsPage(params).then(res => {
        this.tableData = res.data
        this.page.total = res.total
      })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onReset() {
      this.$refs.form.resetFields()
      this.onRefresh()
    },
    onDeptChange(id) {
      this.currentDept = id
      this.onRefresh()
    },
    onCreate() {
      this.formData = {
        title: '',
        department_id:
          this.userSuperId === this.userId
            ? this.currentDept || this.userDepartmentId
            : this.userDepartmentId,
        order: 0,
        content: '',
        media_keys: [],
      }
      this.$refs.detailDialog.open()
    },
    onDetailSuccess() {
      this.$refs.detailDialog.close()
      this.onReset()
    },
    onInfo(row) {
      this.currentRow = row
      this.$refs.infoDialog.open()
    },
    onDelete(row) {
      this.$confirm('确定要删除当前项吗？', { type: 'warning' })
        .then(() => {
          return deleteRecods(row.id)
        })
        .then(() => {
          this.$message.success('删除成功')
          this.onRefresh()
        })
        .catch(() => {})
    },
  },
}
</script>
