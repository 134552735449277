<!--
 * @Author: jiang
 * @Date: 2021-06-12 11:39:09
 * @Description: 目录分类
-->
<template>
  <div>
    <c-tree
      ref="tree"
      :data="treeData"
      :current="currentKey"
      :form-option="option"
      title="分类信息"
      :manager="manager"
      :submit-loading="submitLoading"
      @change="onChange"
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
    ></c-tree>
  </div>
</template>

<script>
import CTree from '@/components/tree'
import { getDeptTree, createDept, updateDept, deleteDept } from '@/api/user'
export default {
  components: {
    CTree,
  },
  props: {
    currentKey: [Number, String],
    manager: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      treeData: [],
      submitLoading: false,
      option: {
        columns: [
          {
            label: '名称',
            prop: 'name',
            inputAttrs: {
              placeholder: '输入名称',
            },
            rules: [{ required: true, message: '必须填写字段', trigger: 'blur' }],
          },
        ],
      },
    }
  },
  created() {
    this.fetchTree()
  },
  methods: {
    getName(id) {
      return this.$refs.tree.getName(id)
    },
    fetchTree() {
      getDeptTree().then(res => {
        this.treeData = [res]
      })
    },
    onChange(data) {
      this.$emit('change', data.id)
    },
    onAdd(data) {
      this.submitLoading = true
      const params = {
        name: data.data.name,
        parent_id: data.tree.parent_id,
      }
      createDept(params)
        .then(res => {
          this.$message.success('添加成功')
          this.fetchTree()
        })
        .finally(() => {
          this.submitLoading = false
          this.$refs.tree.closeDialog()
        })
    },
    onEdit(data) {
      this.submitLoading = true
      const params = {
        name: data.data.name,
        parent_id: data.tree.parent_id,
      }
      return updateDept(data.tree.id, params)
        .then(res => {
          this.$message.success('修改成功')
          this.fetchTree()
        })
        .finally(() => {
          this.submitLoading = false
          this.$refs.tree.closeDialog()
        })
    },
    onDelete(data) {
      this.$confirm('确定要删除当前项吗', '提示', { type: 'warning' })
        .then(() => {
          return deleteDept(data.id)
        })
        .then(res => {
          this.$message.success('删除成功')
          this.fetchTree()
        })
        .catch(() => {})
    },
  },
}
</script>
